import React, { useCallback, useContext, useEffect, useState } from "react"

import { useTranslation } from "react-i18next"

import AngleDown from "../../images/angle-down.png"
import AngleUP from "../../images/angle-up.png"
import { CurrencyContext } from "../store/currencyContext"
import { CartContext } from "../store/cartContext"
import { ProductContext } from "../store/productContext"

const Navbar = ({ metalsData }) => {
  const { t } = useTranslation()
  const [currency, setCurrency] = useState({})
  const currencyContext = useContext(CurrencyContext)
  const [count, setCount] = useState(0)
  const [intervalId, setIntervalId] = useState(null)
  const cartContext = useContext(CartContext)
  const { getCart } = cartContext
  const productContext = useContext(ProductContext)

  const { getPageDetails, globalFilter } = productContext

  const locationRef = typeof window !== "undefined" ? window.location : ""

  const locationPathName =
    locationRef.pathname && locationRef.pathname.split("/").filter(item => item)

  const startRefreshInterval = useCallback(() => {
    // Set up the interval
    setIntervalId(
      setInterval(() => {
        setCount(prevCount => prevCount + 1)
      }, 1000)
    )

    // Clean up the interval when the component unmounts or effect dependencies change
    return () => {
      console.log("Clearing interval:", intervalId)
      setIntervalId(null)
      clearInterval(intervalId)
    }
  }, [intervalId])

  useEffect(() => {
    if (
      !locationPathName.includes("bullion") &&
      !locationPathName.includes("cart")
    ) {
      // console.log("URL NOT matched =========>", { intervalId, count })
      setIntervalId(null)
      clearInterval(intervalId)
      setCount(0)
    } else {
      // console.log("URL matched =========>", { intervalId, count })
      if (!intervalId) {
        startRefreshInterval()
      }

      if (count >= 300) {
        setIntervalId(null)
        clearInterval(intervalId)
        setCount(0)
        if (locationPathName.includes("cart")) {
          getCart()
        }
        if (locationPathName.includes("bullion")) {
          getPageDetails(globalFilter)
        }
      }
    }
  }, [
    count,
    getCart,
    getPageDetails,
    globalFilter,
    intervalId,
    locationPathName,
    startRefreshInterval,
  ])

  useEffect(() => {
    if (currencyContext?.currencyContextData) {
      setCurrency(currencyContext.currencyContextData)
    }
  }, [currencyContext])

  useEffect(() => {
    const currentCurrency = JSON.parse(
      localStorage.getItem("currencyContextSessionValue")
    )
    if (currentCurrency) {
      setCurrency(currentCurrency)
    }
  }, [])

  const formatPrice = price => {
    return currency?.rate ? (price * currency?.rate).toFixed(2) : price
  }

  const formatChange = change => {
    const isNegative = change?.toString().startsWith("-")
    const chngt = isNegative
      ? change.toFixed(2).toString().substring(1)
      : change
    const formattedChange = isNegative
      ? formatPrice(chngt)
      : formatPrice(change)
    return `${isNegative ? "-" : ""}${
      currency?.symbol || "$"
    }${formattedChange}`
  }

  const renderMetalInfo = (metal, dayChange, label) => (
    <li className="price-update-list-item">
      <table className="symbol-block" data-symbol={label.toLowerCase()}>
        <tbody>
          <tr className="metal-info">
            {metal ? (
              <>
                <td className="metal-price-label">
                  <span className="metal-label">{label}:&nbsp;</span>
                  <span className="metal-price">
                    {currency?.symbol || "$"}
                    {formatPrice(+metal)}
                  </span>
                </td>
                &nbsp;&nbsp;
                <td className="metal-change-label">
                  <img
                    src={
                      dayChange?.toString().startsWith("-")
                        ? AngleDown
                        : AngleUP
                    }
                    alt={`${label} price change`}
                  />
                  <span className="metal-day-change">
                    {formatChange(dayChange)}
                  </span>
                </td>
              </>
            ) : (
              "Loading..."
            )}
          </tr>
        </tbody>
      </table>
    </li>
  )

  return (
    <div className="clearfix">
      <ul className="price-update-list">
        {renderMetalInfo(metalsData?.gold, metalsData?.gold_day_change, "Gold")}
        {renderMetalInfo(
          metalsData?.silver,
          metalsData?.silver_day_change,
          "Silver"
        )}
        {renderMetalInfo(
          metalsData?.platinum,
          metalsData?.platinum_day_change,
          "Platinum"
        )}
        {renderMetalInfo(
          metalsData?.palladium,
          metalsData?.palladium_day_change,
          "Palladium"
        )}
        <li className="price-update-list-item">
          <div id="counter">
            Refreshing in: &nbsp;
            <span id="timer">{count === 0 ? "N/A" : 300 - +count}</span> seconds
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Navbar
