import axios from "axios"
import { graphql, useStaticQuery } from "gatsby"
import React, { createContext, useEffect, useState } from "react"
import { toast } from "react-toastify"

export const CountryContext = createContext()

export const CountryContextProvider = ({ children }) => {
  const getCountries = useStaticQuery(graphql`
    {
      wpgraphql {
        getAllCountries {
          code
          name
        }
      }
    }
  `)
  const [countryContextData, setCountryContextData] = useState(
    getCountries?.wpgraphql?.getAllCountries
  )

  useEffect(() => {
    if (!getCountries?.wpgraphql?.getAllCountries) {
      toast.error("Unable to fetch countries data", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
    /*
    const getCountries = async () => {
      try {
        const query = `query getAllCountriesAndListOfStates {
        getAllCountries {
          code
          name
        }
      }`

        const response = await axios.post(
          `${process.env.WP_API_URL}`,
          {
            query: query,
            // variables: variables,
          },
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer YOUR_AUTH_TOKEN`, // If authentication is needed
            },
          }
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.getAllCountries) {
            toast.error("Unable to fetch countries data", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            })
            return
          } else {
            setCountryContextData(response?.data?.data?.getAllCountries)
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    getCountries()
    */
  }, [])

  return (
    <CountryContext.Provider
      value={{ countryContextData, setCountryContextData }}
    >
      {children}
    </CountryContext.Provider>
  )
}
