import React, { createContext, useContext, useEffect, useState } from "react"
import {
  productDefaultListingQuery,
  productDefaultListingQuerySelections,
} from "../helper/query"
import { callGraphQLQuery } from "../helper/callQL"
import CustomToast from "../helper/toast"
import {
  initialFilterValue,
  initialSaleFilterValue,
  initialSelectionsValue,
} from "../helper/constants"
import { AuthContext } from "./authContext"
import { useLocation } from "@reach/router"

export const ProductContext = createContext()

export const ProductContextProvider = ({ children }) => {
  const location = useLocation()

  const authContext = useContext(AuthContext)
  const { authContextData: authToken } = authContext

  const [productsContent, setProductsContent] = useState(null)
  const [showLoader, setLoader] = useState(false)
  const [total, settotal] = useState("")
  const [pageCount, setPageCount] = useState("")
  const [globalFilter, setGlobalFilter] = useState(null)
  const [attributessContent, setAttributessContent] = useState(null)
  const [minPriceVal, setMinPriceVal] = useState(null)
  const [maxPriceVal, setMaxPriceVal] = useState(null)

  const [minMintage, setMinMintage] = useState()
  const [maxMintage, setMaxMintage] = useState()
  const [minYear, setMinYear] = useState()
  const [maxYear, setMaxYear] = useState()

  useEffect(() => {
    location?.pathname === "/bullion/"
      ? setGlobalFilter(initialFilterValue)
      : location?.pathname === "/sale/"
      ? setGlobalFilter(initialSaleFilterValue)
      : location?.pathname?.split("/")?.includes("selection")
      ? setGlobalFilter(initialSelectionsValue)
      : null
  }, [location?.pathname])

  const getPageDetails = async filters => {
    try {
      setLoader(true)
      const response = await callGraphQLQuery(
        productDefaultListingQuerySelections(filters),
        null,
        authToken ? authToken : null
      )
      // Handle the response
      if (response.status === 200) {
        if (response?.data?.data?.productsFilterInit?.total === 0) {
          CustomToast("Products content not found")
          settotal(0)
          setPageCount(0)
          setProductsContent(response?.data?.data?.productsFilterInit?.proData)
          setAttributessContent(
            response?.data?.data?.productsFilterWithAttribute?.attributeData
          )
          setMinPriceVal(response?.data?.data?.minPriceMaxPrice?.minPriceVal)
          setMaxPriceVal(response?.data?.data?.minPriceMaxPrice?.maxPriceVal)

          setMinMintage(
            +response?.data?.data?.attributeRanges?.mintage?.min?.name
          )
          setMaxMintage(
            +response?.data?.data?.attributeRanges?.mintage?.max?.name
          )
          setMinYear(+response?.data?.data?.attributeRanges?.year?.min?.name)
          setMaxYear(+response?.data?.data?.attributeRanges?.year?.max?.name)
        } else {
          const { total = 0, pageCount = 0 } =
            response?.data?.data?.productsFilterInit || {}
          settotal(total ?? 0)
          setPageCount(pageCount ?? 0)
          setProductsContent(response?.data?.data?.productsFilterInit?.proData)
          setAttributessContent(
            response?.data?.data?.productsFilterWithAttribute?.attributeData
          )
          setMinPriceVal(response?.data?.data?.minPriceMaxPrice?.minPriceVal)
          setMaxPriceVal(response?.data?.data?.minPriceMaxPrice?.maxPriceVal)

          setMinMintage(
            +response?.data?.data?.attributeRanges?.mintage?.min?.name
          )
          setMaxMintage(
            +response?.data?.data?.attributeRanges?.mintage?.max?.name
          )
          setMinYear(+response?.data?.data?.attributeRanges?.year?.min?.name)
          setMaxYear(+response?.data?.data?.attributeRanges?.year?.max?.name)
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  const value = {
    getPageDetails,
    pageCount,
    setPageCount,
    total,
    settotal,
    showLoader,
    setLoader,
    productsContent,
    setProductsContent,
    globalFilter,
    setGlobalFilter,
    attributessContent,
    minPriceVal,
    maxPriceVal,
    minMintage,
    maxMintage,
    minYear,
    maxYear,
  }

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  )
}
