import AccountDetailsTab from "../account/tabs/AccountDetailsTab"
import AddressTab from "../account/tabs/AddressTab"
import DashboardTab from "../account/tabs/DashboardTab"
import DownloadsTab from "../account/tabs/DownloadsTab"
import OrdersTab from "../account/tabs/OrdersTab"
import PaymentTab from "../account/tabs/PaymentTab"
import PointsTab from "../account/tabs/PointsTab"
import LeftSideCartSection from "../cart/LeftSideCartSection"
import RightSideCartSection from "../cart/RightSideCartSection"

export const sortingOptions = [
  { value: "menu_order", label: "Default sorting" },
  { value: "popularity", label: "Sort by popularity" },
  { value: "rating", label: "Sort by average rating" },
  { value: "date", label: "Sort by latest" },
  { value: "price", label: "Sort by price: low to high" },
  { value: "price-desc", label: "Sort by price: high to low" },
]

export const menuHeaders = {
  dashboard: "My account",
  orders: "Orders",
  downloads: "Downloads",
  address: "Addresses",
  payment: "Payment methods",
  account_details: "Account details",
  points: "Points",
}

export const menuComponents = {
  dashboard: DashboardTab,
  orders: OrdersTab,
  downloads: DownloadsTab,
  address: AddressTab,
  payment: PaymentTab,
  account_details: AccountDetailsTab,
  points: PointsTab,
}

export const initialFilterValue = {
  bullionType: "",
  after: "1",
  category: "bullion",
  composition: "",
  country: "",
  denomination: "",
  finish: "",
  first: 15,
  grade: "",
  maxPrice: "",
  minPrice: "",
  mintageRangeEnd: "",
  mintageRangeStart: "",
  orderby: "",
  region: "",
  relation: "",
  search: "",
  set: "",
  strikeType: "",
  theme: "",
  weight: "",
  yearRangeEnd: "",
  yearRangeStart: "",
}

export const initialSaleFilterValue = {
  bullionType: "",
  after: "1",
  category: "sale",
  composition: "",
  country: "",
  denomination: "",
  finish: "",
  first: 15,
  grade: "",
  maxPrice: "",
  minPrice: "",
  mintageRangeEnd: "",
  mintageRangeStart: "",
  orderby: "",
  region: "",
  relation: "",
  search: "",
  set: "",
  strikeType: "",
  theme: "",
  weight: "",
  yearRangeEnd: "",
  yearRangeStart: "",
}

export const initialSelectionsValue = {
  bullionType: "",
  after: "1",
  category: "",
  composition: "",
  country: "",
  denomination: "",
  finish: "",
  first: 15,
  grade: "",
  maxPrice: "",
  minPrice: "",
  mintageRangeEnd: "",
  mintageRangeStart: "",
  orderby: "",
  region: "",
  relation: "",
  search: "",
  set: "",
  strikeType: "",
  theme: "",
  weight: "",
  yearRangeEnd: "",
  yearRangeStart: "",
}

export const cartComponents = {
  head: null,
  leftSideSection: LeftSideCartSection,
  rightSideSection: RightSideCartSection,
}

export const cartChangeAddressShippingAddressErrorMessage = {
  country: "Country is required.",
  city: "City is required.",
  // state: "State is required.",
  postcode: "Postcode is required.",
}

export const CART_msg = {
  FTAPOC: "Failed To add product on Cart",
  PAOCS: "Product Added on cart!",
  PUOCS: "Product Updated on cart!",
  PRFCS: "Product remove from cart!",
  FTUPOC: "Failed To Update product on Cart",
  CPUS: "Cart product updated successfully!",
  FTRPFC: "Failed To Remove product from Cart",
  CIR: "Cart item remove successfully!",
  FTAC: "Failed To apply coupoun",
  CA: "Coupon applied Successfully!",
  FTRCFC: "Failed To remove coupoun from cart",
  CR: "Coupon Removed Successfully!",
  FTCSA: "Failed To Change Shipping Address",
  SACS: "Shipping Address Changed Successfully!",
  FTUSAM: "Failed to Update shipping address method",
  SAMU: "Shipping address method updated successfully!",
  FTAP: "Failed to Apply Points",
  PAS: "Points Applied successfully!",
  FTALCI: "Failed to Add local cart Item",
  IAICS: "Items added in cart successfully!",
  IFA: "insurance Fee added!",
  IFNA: "insurance Fee not added !",
  IFFTA: "insurance Fee Fail to add",
  IFRFC: "insurance Fee Removed From cart",
  YOP: "Your order successfully placed",
}

export const AUTH_msg = {
  PEVE: "Please Enter Valid Email!",
}
