import React, { useContext, useState } from "react"
import { CartContext } from "../store/cartContext"
import CustomToast from "../helper/toast"
import { CountryContext } from "../store/countryContext"
import axios from "axios"
import { cartChangeAddressShippingAddressErrorMessage } from "../helper/constants"
import CartPost from "./CartPost"
import Utils from "../helper/utills"
import { Link } from "gatsby"
import { AuthContext } from "../store/authContext"
  import { Slide, ToastContainer } from "react-toastify"
import { CurrencyContext } from "../store/currencyContext"

export default function RightSideCartSection() {
  const cartContext = useContext(CartContext)
  const {
    cartContextData: cartPageData,
    applyCouponCodeApi,
    changeShippingAddressApi,
    removeCouponCodeApi,
    applyPointsDiscountApi,
    addLocalCartProductsToWPSessionUsingUniqueMutationIdApi,
  } = cartContext

  const countryContext = useContext(CountryContext)
  const { countryContextData } = countryContext

  const authContext = useContext(AuthContext)
  const { authContextData: authToken } = authContext

  const currencyContext = useContext(CurrencyContext)
  const { currencyContextData } = currencyContext
  const updatedCurrencyContextData = { ...currencyContextData }

  const [showApplyCoupon, setShowApplyCoupon] = useState(false)
  const [showAddPoint, setShowAddPoint] = useState(false)
  const [CouponCode, setCouponCode] = useState("")
  const [showChangeAddress, setShowChangeAddress] = useState(false)
  const [countryStates, setCountryStates] = useState([])

  const [points, setPoints] = useState("")

  const [contactAddress, setContactAddress] = useState({
    country: "",
    city: "",
    state: "",
    postcode: "",
  })

  function handleClickOnAddACoupon() {
    setShowApplyCoupon(prev => !prev)
  }

  function handleClickOnAddPoints() {
    setShowAddPoint(prev => !prev)
  }

  function afterCouponApplySuccessfullyCallBack() {
    setCouponCode("")
    setShowApplyCoupon(false)
  }

  async function applyCouponCode() {
    if (!CouponCode.length) {
      return CustomToast("Please Enter coupon code")
    }
    applyCouponCodeApi(CouponCode, afterCouponApplySuccessfullyCallBack)
  }

  function handleClickOnChangeAddress() {
    setShowChangeAddress(prev => !prev)
  }

  const handleChangeAddress = _e => {
    setContactAddress(_prevState => ({
      ..._prevState,
      [_e.target.name]: _e.target.value.trim(),
    }))
  }

  const getStates = async (_ev, type = null) => {
    if (!_ev) return
    const variables = {
      country: _ev?.target ? _ev.target.value : _ev,
    }
    try {
      const query = `query getAllCountriesAndListOfStates($country: CountriesEnum!) {
      countryStates(country: $country) {
        code
        name
      }
    }`

      const response = await axios.post(
        `${process.env.WP_API_URL}`,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer YOUR_AUTH_TOKEN`, // If authentication is needed
          },
        }
      )

      // Handle the response
      if (response.status === 200) {
        if (!response?.data?.data?.countryStates) {
          return CustomToast("Unable to fetch States of selected country")
        } else {
          setCountryStates(response?.data?.data?.countryStates)
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  function afterChangedShippingAddressCallback() {
    handleClickOnChangeAddress()
  }

  const handleUpdateOrAddShippingAddress = () => {
    for (const key in contactAddress) {
      if (key !== "state" && contactAddress[key] === "") {
        return CustomToast(cartChangeAddressShippingAddressErrorMessage[key])
      }
    }

    if (countryStates?.length && !contactAddress.state) {
      return CustomToast("State is required...")
    }

    changeShippingAddressApi(
      contactAddress,
      afterChangedShippingAddressCallback
    )
  }

  function afterApplyPointsSuccessfullyCallBack() {
    setPoints("")
    handleClickOnAddPoints()
  }

  const handleRedeamPoints = () => {
    if (!points) {
      return CustomToast("Please Enter points to Redeam.")
    }

    applyPointsDiscountApi(points, afterApplyPointsSuccessfullyCallBack)
  }

  const whenUserIsUnAuthrize = () => {
    addLocalCartProductsToWPSessionUsingUniqueMutationIdApi()
  }

  return (
    <div className="col-lg-4 cart_sec_right">
      <div className="cart_total">
        <span>Cart Totals</span>
      </div>
      {/* {authToken ? ( */}
        <div className="add_coupon_wrap">
          <div className="add_coupon_head" onClick={handleClickOnAddACoupon}>
            <h4>Add a coupon</h4>
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              aria-hidden="true"
              class="wc-block-components-panel__button-icon"
              focusable="false"
            >
              <path d="M17.5 11.6L12 16l-5.5-4.4.9-1.2L12 14l4.5-3.6 1 1.2z"></path>
            </svg>
          </div>

          {showApplyCoupon && (
            <div className="add_coupon_box">
              <div className="add_coupon_box_field">
                <input
                  type="text"
                  name="couponCode"
                  value={CouponCode}
                  onChange={e => setCouponCode(e.target.value)}
                  placeholder="Enter code"
                />
              </div>
              <button onClick={applyCouponCode} type="button">
                Apply
              </button>
            </div>
          )}
        {authToken ? (
          <div className="user_points">
            <span onClick={handleClickOnAddPoints}>Use Points</span>
            {showAddPoint && (
              <div className="shipping_address_box">
                <div className="address_field">
                  <input
                    type="text"
                    className="form-control"
                    name="points"
                    value={points}
                    onChange={e => setPoints(e.target.value)}
                    placeholder="Enter points"
                  />
                </div>
                <div className="address_field update_button_field">
                  <button
                    onClick={handleRedeamPoints}
                    type="button"
                    className="update_button"
                  >
                    Add Points
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : null}
        </div>
      <div className="cart_total_price">
        <span>Subtotal</span>
        <b>{authToken ? cartPageData?.subtotal : cartPageData?.subtotal?.replace("$", updatedCurrencyContextData?.symbol ?? "$")}</b>
      </div>
      {cartPageData?.appliedCouponsData?.length ? (
        <div className="cart_total_price">
          <span>Discount</span>
          <b>
            -${Utils.getTotalDiscountAmount(cartPageData?.appliedCouponsData)}
          </b>
        </div>
      ) : null}
      <div>
        {/* {
          cartPageData?.appliedCoupons?.map((appliedCoupon) => appliedCoupon.code !==
            "Cart Discount" ? <div>
            <span>{appliedCoupon.code}</span>
            <button onClick={() => removeCouponCodeApi(appliedCoupon.code)}>X</button>
          </div> : null
          )
        } */}
        {cartPageData?.appliedCouponsData?.map(appliedCoupon => (
          <div className="discount" key={appliedCoupon.code}>
            <span>{appliedCoupon.code}</span>
            <button onClick={() => removeCouponCodeApi(appliedCoupon.code)}>
              X
            </button>
          </div>
        ))}
      </div>
      <div className="cart_total_price">
        <span>Shipping</span>
        <b>{cartPageData?.shippingTotal}</b>
      </div>
      {/* {authToken ? ( */}
        <div className="shipping_address_box">
          <div className="shipping_address_box_head">
            <span>Shipping to India</span>
            <button onClick={handleClickOnChangeAddress} type="button">
              Change address
            </button>
          </div>
          {showChangeAddress && (
            <div className="shipping_address_box">
              <div className="address_field">
                <select
                  className="form-control"
                  name="country"
                  value={
                    countryContextData
                      ? countryContextData.filter(
                        country => country.code === contactAddress.country
                      )[0]?.code
                      : ""
                  }
                  onChange={_e => {
                    setContactAddress(_prevState => ({
                      ..._prevState,
                      state: "",
                    }))

                    handleChangeAddress(_e)
                    getStates(_e)
                  }}
                >
                  {countryContextData &&
                    countryContextData.map((country, index) => (
                      <option value={country?.code} key={index}>
                        {country.name}
                      </option>
                    ))}
                </select>
              </div>

              {countryStates.length ? (
                <div className="address_field">
                  <select
                    className="form-control"
                    name="state"
                    value={
                      countryStates
                        ? countryStates.filter(
                          state => state.code === contactAddress.state
                        )[0]?.code
                        : ""
                    }
                    onChange={_ev => handleChangeAddress(_ev)}
                  >
                    {countryStates &&
                      countryStates.map((state, index) => (
                        <option value={state?.code} key={index}>
                          {state.name}
                        </option>
                      ))}
                  </select>
                </div>
              ) : null}

              <div className="address_field">
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={contactAddress.city}
                  onChange={handleChangeAddress}
                />
                <label>City</label>
              </div>
              <div className="address_field">
                <input
                  type="text"
                  className="form-control"
                  name="postcode"
                  value={contactAddress.postcode}
                  onChange={handleChangeAddress}
                />
                <label>Pin Code</label>
              </div>
              <div className="address_field update_button_field">
                <button
                  onClick={handleUpdateOrAddShippingAddress}
                  type="button"
                  className="update_button"
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      {/* ) : null} */}
      <div className="cart_page_radio">
        <CartPost />
      </div>
      {/* <div className="cart_total_price">
        <span>Shipping to South Australia, Australia</span>
      </div> */}
      <div className="cart_total_price total_price">
        <b>Total</b>
        {/* <b>{cartPageData?.total}</b> */}
        <b>{authToken ? cartPageData?.total : cartPageData?.total?.replace("$", updatedCurrencyContextData?.symbol ?? "$")}</b>
      </div>
      <div className="checkout_btn">
        {
          authToken ?
            <Link to="/checkout">
              Proceed to Checkout
            </Link> :
            <Link to="/checkout" onClick={whenUserIsUnAuthrize}>
              Proceed to Checkout
            </Link>
        }
      </div>
    </div>
  )
}
