import React, { createContext, useState } from "react"

const authContextSessionValue =
  typeof window !== "undefined"
    ? sessionStorage.getItem("authContextSessionValue")
    : ""

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [authContextData, setAuthContextData] = useState(
    authContextSessionValue ?? ""
  )

  return (
    <AuthContext.Provider value={{ authContextData, setAuthContextData }}>
      {children}
    </AuthContext.Provider>
  )
}
