import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { AuthContext } from "../../store/authContext"
import LogoutIcon from "../../../images/logout.png"
import PhoneIcon from "../../../images/phone.png"
import EmailIcon from "../../../images/email.png"
import axios from "axios"
import { toast } from "react-toastify"
import { CurrencyContext } from "../../store/currencyContext"
import { CountryContext } from "../../store/countryContext"

export default function PaymentTab() {
    return (
        <div className="account_details_tab">
            <div className="payment_page">
                <div className="row">
                    <div className="col-lg-12 payment_sec">
                        <a href="#" className="add_payment_button">
                            Add payment method
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
