import React, { useContext, useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { AuthContext } from "../../store/authContext"
import LogoutIcon from "../../../images/logout.png"
import PhoneIcon from "../../../images/phone.png"
import EmailIcon from "../../../images/email.png"
import axios from "axios"
import { toast } from "react-toastify"
import { CurrencyContext } from "../../store/currencyContext"
import { CountryContext } from "../../store/countryContext"
import { callGraphQLQuery } from "../../helper/callQL"
import { getOrderDetails } from "../../helper/query"

export default function OrderDetails({ setOrderNumber, orderNumber }) {
  const authContext = useContext(AuthContext)
  const [showLoader, setLoader] = useState(false)
  const [orderDetails, setOrderDetails] = useState(null)

  useEffect(() => {
    if (orderNumber) {
      getOrderDetailsByOrderID(orderNumber ?? "")
    }
  }, [])

  const getOrderDetailsByOrderID = async id => {
    try {
      const { authContextData: authToken } = authContext

      setLoader(true)
      const response = await callGraphQLQuery(
        getOrderDetails(id),
        null,
        authToken
      )

      // Handle the response
      // console.log("==========>response", response);
      if (response.status === 200) {
        if (!response?.data?.data?.order) {
          toast.error("Order Details not found", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        } else {
          setOrderDetails(response?.data?.data?.order)
        }
        setLoader(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const formatDate = dateString => {
    const date = new Date(dateString)
    const options = { year: "numeric", month: "long", day: "numeric" }
    const formattedDate = date.toLocaleDateString("en-US", options)
    return formattedDate
  }

  const formatPrice = price => {
    const numprice = parseFloat(price.replace("$", ""))
    return `$${numprice.toFixed(2)}`
  }
  return showLoader ? (
    <div className="loader_bg_auth">
      <div class="loader_auth"></div>
    </div>
  ) : (
    <div className="order_details_sec">
      <div className="order_details_top">
        <button className="prev_next_btn" onClick={() => setOrderNumber(null)}>Back To order</button>
        <p>
          Order <b># {orderDetails?.orderNumber}</b> was placed on{" "}
          <b>{formatDate(orderDetails?.date)}</b> and is currently{" "}
          <b>{orderDetails?.status}</b>
        </p>
        <h3>Order details</h3>
      </div>
      <div className="order_details_table">
        <table cellPadding={0} cellSpacing={0} border={1}>
          <thead>
            <tr>
              <th>Product</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {orderDetails?.lineItems?.nodes?.map(lineItem => (
              <tr>
                <td data-th="Product">
                  <Link
                    to={`/bullion/${lineItem?.product?.node?.uri
                      ?.split("/")
                      .slice(2)
                      .join("/")}`}
                  >
                    {lineItem?.product?.node?.name}
                  </Link>
                  <strong>X {lineItem?.quantity}</strong>
                  {lineItem.metaData
                    ?.filter(item => item.key === "Backordered")
                    .map(fItem => (
                      <strong>
                        {" "}
                        {fItem.key} : {fItem.value}
                      </strong>
                    ))}
                </td>
                <td data-th="Total">{formatPrice(lineItem?.subtotal)}</td>
              </tr>
            ))}
            <tr>
              <td data-th="Product">
                <strong>Subtotal:</strong>
              </td>
              <td data-th="Total">
                <b>{orderDetails?.subtotal}</b>
              </td>
            </tr>

            {orderDetails?.discountTotal && (
              <tr>
                <td data-th="Product">
                  <strong>Discount:</strong>
                </td>
                <td data-th="Total">
                  <b>- {orderDetails?.discountTotal}</b>
                </td>
              </tr>
            )}
            <tr>
              <td data-th="Product">
                <strong>Shipping:</strong>
              </td>
              <td data-th="Total">
                {orderDetails?.shippingLines.nodes?.map(item => (
                  <span>
                    {" "}
                    <b>{orderDetails.shippingTotal}</b> - {item.methodTitle}
                  </span>
                ))}
              </td>
            </tr>

            {orderDetails?.feeLines.nodes.length > 0 &&
              orderDetails?.feeLines.nodes?.map(item => (
                <tr>
                  <td data-th="Product">
                    <strong>{item.name}:</strong>
                  </td>
                  <td data-th="Total">
                    <b>{formatPrice(item.total)}</b>
                  </td>
                </tr>
              ))}
            <tr>
              <td data-th="Product">
                <strong>Payment method:</strong>
              </td>
              <td data-th="Total">
                <b>{orderDetails?.paymentMethodTitle}</b>
              </td>
            </tr>
            <tr>
              <td data-th="Product">
                <strong>Total:</strong>
              </td>
              <td data-th="Total">
                <b> {orderDetails?.total}</b>
              </td>
            </tr>
            {orderDetails?.customerNote && (
              <tr>
                <td data-th="Product">
                  <strong>Note:</strong>
                </td>
                <td data-th="Total">
                  <b> {orderDetails?.customerNote}</b>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {orderDetails?.billing && (
        <div className="order_details_address">
          <h3>Billing address</h3>
          <address>
            <div className="address_under_p">
              {orderDetails?.billing.firstName && (
                <p>
                  {orderDetails?.billing.firstName}{" "}
                  {orderDetails?.billing.lastName}
                </p>
              )}
              {orderDetails?.billing.company && (
                <p>{orderDetails?.billing.company}</p>
              )}
              {orderDetails?.billing.address1 && (
                <p>{orderDetails?.billing.address1}</p>
              )}
              {orderDetails?.billing.address2 && (
                <p>{orderDetails?.billing.address2}</p>
              )}
              <p>
                {orderDetails?.billing.city} {orderDetails?.billing.state}{" "}
                {orderDetails?.billing.postcode}
              </p>
            </div>

            <p>
              <img src={PhoneIcon} /> {orderDetails?.billing.phone}
            </p>
            <p>
              <img src={EmailIcon} /> {orderDetails?.billing.email}
            </p>
          </address>
        </div>
      )}
      {orderDetails?.shipping && (
        <div className="shipping-address order_details_address">
          <h3>Shipping address</h3>
          <address>
            <div className="address_under_p">
              {orderDetails?.shipping.firstName && (
                <p>
                  {orderDetails?.shipping.firstName}{" "}
                  {orderDetails?.shipping.lastName}
                </p>
              )}
              {orderDetails?.shipping.company && (
                <p>{orderDetails?.shipping.company}</p>
              )}
              {orderDetails?.shipping.address1 && (
                <p>{orderDetails?.shipping.address1}</p>
              )}
              {orderDetails?.shipping.address2 && (
                <p>{orderDetails?.shipping.address2}</p>
              )}
              <p>
                {orderDetails?.shipping.city} {orderDetails?.shipping.state}{" "}
                {orderDetails?.shipping.postcode}
              </p>
            </div>
          </address>
        </div>
      )}
    </div>
  )
}
