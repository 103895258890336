import React,{useState}from 'react'
import ToggleSection from "../../helper/toggleSection";
const ViewPointsLogDetails = ({pontsDetails}) => {

  const [viewSpentPoints, setViewSpentPoints] = useState(false);
  const [viewAppliedPoints, setViewAppliedPoints] = useState(false);
  const handleToggleSpentPoints = () => setViewSpentPoints(prev => !prev);
  const handleToggleAppliedPoints = () => setViewAppliedPoints(prev => !prev);
  const [showLoader, setLoader] = useState(false)
  return (
   
    showLoader ? (
      <div className="loader_bg_auth">
        <div class="loader_auth"></div>
      </div>) :
    <div className="woocommerce-MyAccount-content">
              <div className="woocommerce-notices-wrapper"></div>
              <h2>Point Log Table</h2>
              {pontsDetails.pro_conversion_pointsData && (
                <ToggleSection
                  title="Per Currency Spent Points"
                  isOpen={viewSpentPoints}
                  onToggle={handleToggleSpentPoints}
                >
                  <table className="wps_wpr_common_table">
                    <thead>
                      <tr>
                        <th className="wps-wpr-view-log-Date">Date</th>
                        <th className="wps-wpr-view-log-Status">Point Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pontsDetails.pro_conversion_pointsData.map(item => (
                        <tr key={item.refered_order_id}>
                          <td>{item.date}</td>
                          <td>+{item.pro_conversion_points}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ToggleSection>
              )}

              {pontsDetails.cart_subtotal_pointData && (
                <ToggleSection
                  title="Points Applied on Cart"
                  isOpen={viewAppliedPoints}
                  onToggle={handleToggleAppliedPoints}
                >
                  <table className="wps_wpr_common_table">
                    <thead>
                      <tr>
                        <th className="wps-wpr-view-log-Date">Date</th>
                        <th className="wps-wpr-view-log-Status">Point Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pontsDetails.cart_subtotal_pointData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.date}</td>
                          <td>-{item.cart_subtotal_point}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ToggleSection>
              )}

              {pontsDetails.totalPoits && (
                <div className="wps_wpr_slide_toggle">
                  <table className="wps_wpr_total_points">
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <h4><b>Total Points</b></h4>
                        </td>
                        <td>
                          <h4><b>{pontsDetails.totalRemainPoits}</b></h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
  )
}

export default ViewPointsLogDetails
