// export const  setCookie = (name, value, days)=> {
//   console.log("Adding process")
//   let expires = '';
//   if (days) {
//     const date = new Date();
//     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
//     expires = '; expires=' + date.toUTCString();
//   }
//   document.cookie = name + '=' + (value || 0) + expires + '; path=/';
// }

export const setCookie = (name, value, days) => {
  // console.log("Adding process", value)

  // Validate inputs
  if (!name || !value) {
    console.error("Cookie name and value must be provided")
    return
  }

  let expires = ""
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
  }

  // Encode the value to handle special characters
  const encodedValue = encodeURIComponent(value)

  // Set the cookie
  if (typeof window !== "undefined")
    document.cookie = `${name}=${encodedValue}${expires}; path=/`

  console.log(`Cookie "${name}" set successfully.`)
}

export const getCookie = name => {
  const nameEQ = name + "="
  const cookiesArray =
    typeof window !== "undefined" && document.cookie.split(";")

  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i].trim()

    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }

  return null
}
export const deleteCookie = name => {
  if (typeof window !== "undefined")
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
}

export const clearAllCookies = () => {
  const cookies = typeof window !== "undefined" && document.cookie.split(";")

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf("=")
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie

    // Delete the cookie by setting its expiration date to the past
    if (typeof window !== "undefined")
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  }

  console.log("All cookies cleared.")
}
