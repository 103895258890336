import { toast } from 'react-toastify';

export default function CustomToast(message, type = 'error') {
  if (typeof window !== 'undefined') {
    console.log('Attempting to show toast:', { message, type });
    try {
      toast[type](message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      console.error('Error showing toast:', error);
    }
  } else {
    console.log('Toast not shown (not in browser):', { message, type });
  }
};
// type ("info", "success", "warn", "error", etc.)