// import React, { useEffect } from "react"
// import "./CustomGoogleTranslate.css"

// const GoogleTranslate = () => {
//   useEffect(() => {
//     const initGoogleTranslate = () => {
//       if (window.google && window.google.translate) {
//         new window.google.translate.TranslateElement(
//           {
//             pageLanguage: "en",
//             includedLanguages: "ar,fr,zh-CN,es,ru,pt,it,de,nl,en",
//             layout:
//               window.google.translate.TranslateElement.InlineLayout.SIMPLE,
//             autoDisplay: false,
//           },
//           "google_translate_element"
//         )

//         // Wait for the dropdown to be created
//         setTimeout(customizeDropdown, 1000)
//       }
//     }

//     const customizeDropdown = () => {
//       const selectElement = document.querySelector(".goog-te-combo")
//       if (selectElement) {
//         selectElement.style.border = "1px solid #ccc"
//         selectElement.style.borderRadius = "4px"
//         selectElement.style.padding = "5px"
//         selectElement.style.width = "200px"
//         selectElement.style.fontSize = "14px"
//         selectElement.style.color = "black"
//         selectElement.style.backgroundColor = "white"

//         // Remove Google branding
//         const googleBranding = document.querySelector(".goog-logo-link")
//         if (googleBranding) {
//           googleBranding.style.display = "none"
//         }
//         const googleLogo = document.querySelector(".goog-te-gadget-icon")
//         if (googleLogo) {
//           googleLogo.style.display = "none"
//         }

//         // Change the text of the first option
//         const firstOption = selectElement.querySelector("option")
//         if (firstOption) {
//           firstOption.textContent = "Select Language"
//         }
//       }
//     }

//     if (!window.google) {
//       // Check if the script is already added
//       const existingScript = document.getElementById("google-translate-script")
//       if (!existingScript) {
//         const script = document.createElement("script")
//         script.id = "google-translate-script"
//         script.src =
//           "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
//         script.async = true
//         document.body.appendChild(script)
//         window.googleTranslateElementInit = initGoogleTranslate
//       }
//     } else {
//       initGoogleTranslate()
//     }
//   }, [])

//   return (
//     <div className="header_top_dropdown_translate">
//       <div id="google_translate_element"></div>
//     </div>
//   )
// }

// export default GoogleTranslate

// ***************************************

// import React, { useEffect } from "react"
// import "./CustomGoogleTranslate.css"

// const GoogleTranslate = () => {
//   useEffect(() => {
//     const initGoogleTranslate = () => {
//       if (window.google && window.google.translate) {
//         new window.google.translate.TranslateElement(
//           {
//             pageLanguage: "en",
//             includedLanguages: "ar,fr,zh-CN,es,ru,pt,it,de,nl,en",
//             layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
//             autoDisplay: false,
//           },
//           "google_translate_element"
//         )

//         // Wait for the dropdown to be created
//         setTimeout(customizeDropdown, 1000)
//       }
//     }

//     const customizeDropdown = () => {
//       const selectElement = document.querySelector(".goog-te-gadget-simple")
//       if (selectElement) {
//         // selectElement.style.border = "1px solid #ccc"
//         // selectElement.style.borderRadius = "4px"
//         // selectElement.style.padding = "5px"
//         // selectElement.style.width = "200px"
//         // selectElement.style.fontSize = "14px"
//         // selectElement.style.color = "black"
//         // selectElement.style.backgroundColor = "white"

//         // Remove Google branding
//         // const googleBranding = document.querySelector(".goog-logo-link")
//         // if (googleBranding) {
//         //   googleBranding.style.display = "none"
//         // }

//         const googleLogo = document.querySelector(".goog-te-gadget-icon")
//         if (googleLogo) {
//           googleLogo.style.display = "none"
//         }

//         // Change the text of the first option
//         // const firstOption = selectElement.querySelector("option")
//         // if (firstOption) {
//         //   firstOption.textContent = "Select Language=="
//         // }

//         const link = selectElement.querySelector("a");
//         if (link) {
//           link.querySelector("span").textContent = "Select Language===  ";
//         }

//       }

//       // Set the height of the iframe to 0px
//       const iframe = document.querySelector("iframe")

//       console.log("=============iframe", iframe)
//       if (iframe) {
//         iframe.style.height = "0px"
//         iframe.style.border = "none" // Optional: remove the border as well
//       }
//     }

//     if (!window.google) {
//       // Check if the script is already added
//       const existingScript = document.getElementById("google-translate-script")
//       if (!existingScript) {
//         const script = document.createElement("script")
//         script.id = "google-translate-script"
//         script.src =
//           "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
//         script.async = true
//         document.body.appendChild(script)
//         window.googleTranslateElementInit = initGoogleTranslate
//       }
//     } else {
//       initGoogleTranslate()
//     }
//   }, [])

//   return (
//     <div className="header_top_dropdown_translate">
//       <div id="google_translate_element"></div>
//     </div>
//   )
// }

// export default GoogleTranslate

import React, { useEffect } from "react"
import "./CustomGoogleTranslate.css"

import arabicFlag from "../../images/flags/ar.png"
import ChineseFlag from "../../images/flags/fr.png"
import futchFlag from "../../images/flags/zh-CN.png"
import englishFlag from "../../images/flags/en.png"
import frenchFlag from "../../images/flags/ru.png"
import germanFlag from "../../images/flags/pt.png"
import italianFlag from "../../images/flags/it.png"
import portugueseFlag from "../../images/flags/de.png"
import russianFlag from "../../images/flags/nl.png"
import spanishFlag from "../../images/flags/es.png"

const GoogleTranslate = () => {
  // Example function to get language code from text
  const getLangCodeFromText = text => {
    const langMap = {
      Arabic: "ar",
      "Chinese (Simplified)": "zh-CN",
      Dutch: "nl",
      French: "fr",
      German: "de",
      Italian: "it",
      "Portuguese (Brazil)": "pt",
      Russian: "ru",
      Spanish: "es",
    }
    return langMap[text] || ""
  }

  useEffect(() => {
    const initGoogleTranslate = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "ar,fr,zh-CN,es,ru,pt,it,de,nl,en",
            // includedLanguages: Object.keys(flagUrls).join(","),
            layout:
              window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false,
          },
          "google_translate_element"
        )

        // Wait for the dropdown to be created
        setTimeout(() => {
          customizeDropdown()
          observeDropdownChanges()

          const savedLanguage = localStorage.getItem("selectedLanguage")

          if (savedLanguage) {
            const iframe = document.querySelector("iframe")
            if (iframe) {
              const iframeDoc =
                iframe.contentDocument || iframe.contentWindow.document
              const selectElement = iframeDoc.querySelector("select")
              if (selectElement) {
                selectElement.value = savedLanguage
                selectElement.dispatchEvent(new Event("change"))
              }
            }
          }
        }, 1000)
      }
    }

    const customizeDropdown = () => {
      const selectElement = document.querySelector(".goog-te-gadget-simple")
      if (selectElement) {
        const googleLogo = document.querySelector(".goog-te-gadget-icon")
        if (googleLogo) {
          googleLogo.style.display = "none"
        }

        const link = selectElement.querySelector("a")
        if (link) {
          // link.querySelector("span").textContent = "Select Language";
          const span = link.querySelector("span")
          if (span && span?.textContent) {
            const flagUrls = {
              Arabic: arabicFlag,
              "Chinese (Simplified)": ChineseFlag,
              Dutch: futchFlag,
              English: englishFlag,
              French: frenchFlag,
              German: germanFlag,
              Italian: italianFlag,
              Portuguese: portugueseFlag,
              Russian: russianFlag,
              Spanish: spanishFlag,
            }

            // const selectedLangCode = localStorage.getItem("selectedLanguage");
            // const selectedLangCode = "";

            if (span?.textContent === "Select Language") {
              span.innerHTML = ""
              const selectedFlagUrl = flagUrls["English"]
              const img = document.createElement("img")
              img.src = selectedFlagUrl
              img.alt = "English"
              img.style.width = "22px" // Adjust size as needed
              img.style.height = "20px" // Adjust size as needed
              img.style.marginRight = "8px" // Space between flag and text
              img.style.objectFit = "contain"
              span.appendChild(img)
              // span.appendChild(document.createTextNode(` ${selectedLangCode.toUpperCase()}`));

              const span2 = document.createElement("span")

              span2.innerHTML = "English"
              span.appendChild(span2)
            } else {
              // const flags = Object.keys(flagUrls);
              // if (selectedLangCode && flags.includes(selectedLangCode)) {

              const textInSpan = span.textContent
              span.innerHTML = "" // Clear the span content
              // const selectedFlagUrl = flagUrls[selectedLangCode];

              const selectedFlagUrl = flagUrls[textInSpan]
              const img = document.createElement("img")
              img.src = selectedFlagUrl
              img.alt = span?.textContent
              img.style.width = "30px" // Adjust size as needed
              img.style.height = "25px" // Adjust size as needed
              img.style.marginRight = "8px" // Space between flag and text
              span.appendChild(img)
              // span.appendChild(document.createTextNode(` ${selectedLangCode.toUpperCase()}`));

              //  span.innerHTML = textInSpan;

              const span2 = document.createElement("span")

              span2.innerHTML = textInSpan
              span.appendChild(span2)

              // } else {
              //   span.textContent = "Select Language";
              // }
            }
            // Update the text with the currently selected language

            console.log("======>", span, span.textContent)
          } else {
            span.textContent = "Select Language"
          }
        }

        // const links = selectElement.querySelectorAll("a");

        // console.log("=============>//links", links)
        // // const links = selectElement.querySelectorAll("a");
        // links.forEach(link => {
        //   const langText = link.querySelector("span").textContent.trim();
        //   console.log("=============>//langText", langText)
        //   const langCode = getLangCodeFromText(langText); // Map text to language code
        //   console.log("=============>//langCode", langCode)
        //   const flagUrl = flagUrls[langCode];
        //   console.log("=============>//flagUrl", flagUrl)

        //   if (flagUrl) {
        //     // Clear existing content
        //     link.innerHTML = "";

        //     // Add flag image
        //     const img = document.createElement("img");
        //     img.src = flagUrl;
        //     img.alt = langCode;
        //     img.style.width = "20px"; // Adjust size as needed
        //     img.style.height = "15px"; // Adjust size as needed
        //     img.style.marginRight = "8px"; // Space between flag and text

        //     // Add flag to link
        //     link.appendChild(img);

        //     // Add text back if needed
        //     const textSpan = document.createElement("span");
        //     textSpan.textContent = langText;
        //     link.appendChild(textSpan);
        //   }
        // });
      }

      hideIframe()
    }

    const hideIframe = () => {
      const iframe = document.querySelector("iframe")
      if (iframe) {
        // iframe.style.height = "0px";
        iframe.style.border = "none" // Optional: remove the border as well
      }
    }

    const observeDropdownChanges = () => {
      // Create a MutationObserver to detect changes in the DOM
      const observer = new MutationObserver(() => {
        hideIframe()
      })

      // Observe changes to the body and its descendants
      observer.observe(document.body, { childList: true, subtree: true })
    }

    if (!window.google) {
      // Check if the script is already added
      const existingScript = document.getElementById("google-translate-script")
      if (!existingScript) {
        const script = document.createElement("script")
        script.id = "google-translate-script"
        script.src =
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        script.async = true
        document.body.appendChild(script)
        window.googleTranslateElementInit = initGoogleTranslate
      }
    } else {
      initGoogleTranslate()
    }
  }, [])

  return (
    <div className="header_top_dropdown_translate">
      <div id="google_translate_element"></div>
    </div>
  )
}

export default GoogleTranslate

// import React, { useEffect } from "react";
// import "./CustomGoogleTranslate.css";

// const GoogleTranslate = () => {
//   useEffect(() => {
//     const initGoogleTranslate = () => {
//       if (window.google && window.google.translate) {
//         new window.google.translate.TranslateElement(
//           {
//             pageLanguage: "en",
//             includedLanguages: "ar,fr,zh-CN,es,ru,pt,it,de,nl,en",
//             layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
//             autoDisplay: false,
//           },
//           "google_translate_element"
//         );

//         setTimeout(() => {
//           customizeDropdown();
//           observeDropdownChanges();
//           loadSavedLanguage();
//         }, 1000);
//       }
//     };

//     const customizeDropdown = () => {
//       const selectElement = document.querySelector(".goog-te-gadget-simple");
//       if (selectElement) {
//         const googleLogo = document.querySelector(".goog-te-gadget-icon");
//         if (googleLogo) {
//           googleLogo.style.display = "none";
//         }

//         const link = selectElement.querySelector("a");
//         if (link) {
//           const span = link.querySelector("span");
//           if (span) {
//             console.log("==========>", span)
//             const selectedLangCode = localStorage.getItem("selectedLanguage") || "en"; // Default to English
//             const flagUrls = {
//               ar: "path_to_arabic_flag.png",
//               fr: "path_to_french_flag.png",
//               "zh-CN": "path_to_chinese_flag.png",
//               es: "path_to_spanish_flag.png",
//               ru: "path_to_russian_flag.png",
//               pt: "path_to_portuguese_flag.png",
//               it: "path_to_italian_flag.png",
//               de: "path_to_german_flag.png",
//               nl: "path_to_dutch_flag.png",
//               en: "path_to_english_flag.png",
//             };

//             if (flagUrls[selectedLangCode]) {
//               span.innerHTML = "";
//               const selectedFlagUrl = flagUrls[selectedLangCode];
//               const img = document.createElement("img");
//               img.src = selectedFlagUrl;
//               img.alt = selectedLangCode;
//               img.style.width = "20px";
//               img.style.height = "15px";
//               img.style.marginRight = "8px";
//               span.appendChild(img);
//               span.appendChild(document.createTextNode(` ${selectedLangCode.toUpperCase()}`));
//             } else {
//               span.textContent = "Select Language";
//             }

//             addFlagsToDropdown(link);
//           }
//         }
//       }

//       hideIframe();
//     };

//     const addFlagsToDropdown = (link) => {
//       const options = link.querySelectorAll("option");
//       const flagUrls = {
//         ar: "path_to_arabic_flag.png",
//         fr: "path_to_french_flag.png",
//         "zh-CN": "path_to_chinese_flag.png",
//         es: "path_to_spanish_flag.png",
//         ru: "path_to_russian_flag.png",
//         pt: "path_to_portuguese_flag.png",
//         it: "path_to_italian_flag.png",
//         de: "path_to_german_flag.png",
//         nl: "path_to_dutch_flag.png",
//         en: "path_to_english_flag.png",
//       };

//       options.forEach(option => {
//         const langCode = option.value;
//         const flagUrl = flagUrls[langCode];
//         if (flagUrl) {
//           option.textContent = "";
//           const img = document.createElement("img");
//           img.src = flagUrl;
//           img.alt = langCode;
//           img.style.width = "20px";
//           img.style.height = "15px";
//           img.style.marginRight = "8px";
//           option.appendChild(img);
//         }
//       });
//     };

//     const hideIframe = () => {
//       const iframe = document.querySelector("iframe");
//       if (iframe) {
//         iframe.style.height = "0px";
//         iframe.style.border = "none";
//       }
//     };

//     const observeDropdownChanges = () => {
//       const observer = new MutationObserver(() => {
//         hideIframe();
//         saveSelectedLanguage();
//       });

//       observer.observe(document.body, { childList: true, subtree: true });
//     };

//     const saveSelectedLanguage = () => {
//       const selectedOption = document.querySelector(".goog-te-gadget-simple a");
//       console.log("====selectedOption", selectedOption)
//       if (selectedOption) {
//         const langCode = selectedOption.getAttribute("lang") || "";
//         localStorage.setItem("selectedLanguage", langCode);
//       }
//     };

//     const loadSavedLanguage = () => {
//       const savedLangCode = localStorage.getItem("selectedLanguage");
//       if (savedLangCode) {
//         // Implement the logic to set the saved language if possible
//       }
//     };

//     if (!window.google) {
//       const existingScript = document.getElementById("google-translate-script");
//       if (!existingScript) {
//         const script = document.createElement("script");
//         script.id = "google-translate-script";
//         script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
//         script.async = true;
//         document.body.appendChild(script);
//         window.googleTranslateElementInit = initGoogleTranslate;
//       }
//     } else {
//       initGoogleTranslate();
//     }
//   }, []);

//   return (
//     <div className="header_top_dropdown_translate">
//       <div id="google_translate_element"></div>
//     </div>
//   );
// };

// export default GoogleTranslate;

// import React, { useEffect } from "react";
// import "./CustomGoogleTranslate.css";

// const GoogleTranslate = () => {
//   useEffect(() => {
//     const initGoogleTranslate = () => {
//       if (window.google && window.google.translate) {
//         new window.google.translate.TranslateElement(
//           {
//             pageLanguage: "en",
//             includedLanguages: "ar,fr,zh-CN,es,ru,pt,it,de,nl,en",
//             layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
//             autoDisplay: false,
//           },
//           "google_translate_element"
//         );

//         setTimeout(() => {
//           customizeDropdown();
//           observeDropdownChanges();
//           loadSavedLanguage();
//         }, 1000);
//       }
//     };

//     const customizeDropdown = () => {
//       const selectElement = document.querySelector(".goog-te-gadget-simple");
//       if (selectElement) {
//         const googleLogo = document.querySelector(".goog-te-gadget-icon");
//         if (googleLogo) {
//           googleLogo.style.display = "none";
//         }

//         const link = selectElement.querySelector("a");
//         if (link) {
//           const span = link.querySelector("span");
//           if (span) {
//             const selectedLangCode = localStorage.getItem("selectedLanguage") || "en"; // Default to English
//             const flagUrls = {
//               ar: "path_to_arabic_flag.png",
//               fr: "path_to_french_flag.png",
//               "zh-CN": "path_to_chinese_flag.png",
//               es: "path_to_spanish_flag.png",
//               ru: "path_to_russian_flag.png",
//               pt: "path_to_portuguese_flag.png",
//               it: "path_to_italian_flag.png",
//               de: "path_to_german_flag.png",
//               nl: "path_to_dutch_flag.png",
//               en: "path_to_english_flag.png",
//             };

//             if (flagUrls[selectedLangCode]) {
//               span.innerHTML = "";
//               const selectedFlagUrl = flagUrls[selectedLangCode];
//               const img = document.createElement("img");
//               img.src = selectedFlagUrl;
//               img.alt = selectedLangCode;
//               img.style.width = "20px";
//               img.style.height = "15px";
//               img.style.marginRight = "8px";
//               span.appendChild(img);
//               span.appendChild(document.createTextNode(` ${selectedLangCode.toUpperCase()}`));
//             } else {
//               span.textContent = "Select Language";
//             }

//             addFlagsToDropdown(link);
//           }
//         }
//       }

//       hideIframe();
//     };

//     const addFlagsToDropdown = (link) => {
//       const options = link.querySelectorAll("option");
//       const flagUrls = {
//         ar: "path_to_arabic_flag.png",
//         fr: "path_to_french_flag.png",
//         "zh-CN": "path_to_chinese_flag.png",
//         es: "path_to_spanish_flag.png",
//         ru: "path_to_russian_flag.png",
//         pt: "path_to_portuguese_flag.png",
//         it: "path_to_italian_flag.png",
//         de: "path_to_german_flag.png",
//         nl: "path_to_dutch_flag.png",
//         en: "path_to_english_flag.png",
//       };

//       options.forEach(option => {
//         const langCode = option.value;
//         const flagUrl = flagUrls[langCode];
//         if (flagUrl) {
//           option.textContent = "";
//           const img = document.createElement("img");
//           img.src = flagUrl;
//           img.alt = langCode;
//           img.style.width = "20px";
//           img.style.height = "15px";
//           img.style.marginRight = "8px";
//           option.appendChild(img);
//         }
//       });
//     };

//     const hideIframe = () => {
//       const iframe = document.querySelector("iframe");
//       if (iframe) {
//         iframe.style.height = "0px";
//         iframe.style.border = "none";
//       }
//     };

//     const observeDropdownChanges = () => {
//       const observer = new MutationObserver(() => {
//         hideIframe();
//         saveSelectedLanguage();
//       });

//       observer.observe(document.body, { childList: true, subtree: true });
//     };

//     const saveSelectedLanguage = () => {
//       const selectedOption = document.querySelector(".goog-te-gadget-simple a");
//       console.log("====selectedOption", selectedOption);
//       if (selectedOption) {
//         const langCode = selectedOption.getAttribute("lang") || "";
//         console.log("====langCode", langCode);
//         localStorage.setItem("selectedLanguage", langCode);
//       }
//     };

//     const loadSavedLanguage = () => {
//       const savedLangCode = localStorage.getItem("selectedLanguage");

//       const iframe = document.querySelector("iframe");

//       console.log("============iframe", iframe)
//       if (savedLangCode) {
//         // Implement logic to set the saved language if possible
//         // This will depend on how Google Translate allows setting the language programmatically

//         const iframe = document.querySelector("iframe");

//         console.log("============iframe", iframe)
//         if (iframe) {
//           const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
//           const selectElement = iframeDoc.querySelector("select");
//           if (selectElement) {
//             selectElement.value = savedLanguage;
//             selectElement.dispatchEvent(new Event("change"));
//           }
//         }

//       }
//     };

//     if (!window.google) {
//       const existingScript = document.getElementById("google-translate-script");
//       if (!existingScript) {
//         const script = document.createElement("script");
//         script.id = "google-translate-script";
//         script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
//         script.async = true;
//         document.body.appendChild(script);
//         window.googleTranslateElementInit = initGoogleTranslate;
//       }
//     } else {
//       initGoogleTranslate();
//     }
//   }, []);

//   return (
//     <div className="header_top_dropdown_translate">
//       <div id="google_translate_element"></div>
//     </div>
//   );
// };

// export default GoogleTranslate;

// ****************************************

// import React, { useEffect, useState } from "react";
// import "./CustomGoogleTranslate.css";

// const GoogleTranslate = () => {
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState("English");

//   const languages = [
//     { code: "en", name: "English", flag: "🇬🇧" },
//     { code: "ar", name: "Arabic", flag: "🇸🇦" },
//     { code: "zh-CN", name: "Chinese (Simplified)", flag: "🇨🇳" },
//     { code: "nl", name: "Dutch", flag: "🇳🇱" },
//     { code: "fr", name: "French", flag: "🇫🇷" },
//     { code: "de", name: "German", flag: "🇩🇪" },
//     { code: "it", name: "Italian", flag: "🇮🇹" },
//     { code: "pt", name: "Portuguese", flag: "🇵🇹" },
//     { code: "ru", name: "Russian", flag: "🇷🇺" },
//     { code: "es", name: "Spanish", flag: "🇪🇸" },
//   ];

//   useEffect(() => {
//     const initGoogleTranslate = () => {
//       if (window.google && window.google.translate) {
//         new window.google.translate.TranslateElement(
//           {
//             pageLanguage: "en",
//             includedLanguages: languages.map(lang => lang.code).join(","),
//             layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
//             autoDisplay: false,
//           },
//           "google_translate_element"
//         );
//       }
//     };

//     if (!window.google) {
//       const script = document.createElement("script");
//       script.src =
//         "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
//       script.async = true;
//       document.body.appendChild(script);
//       window.googleTranslateElementInit = initGoogleTranslate;
//     } else {
//       initGoogleTranslate();
//     }
//   }, []);

//   const handleLanguageChange = (languageName) => {
//     setSelectedLanguage(languageName);
//     setShowDropdown(false);
//     // Trigger Google Translate
//     const selectElement = document.querySelector(".goog-te-combo");
//     if (selectElement) {
//       selectElement.value = languages.find(lang => lang.name === languageName).code;
//       selectElement.dispatchEvent(new Event('change'));
//     }
//   };

//   return (
//     <div className="header_top_dropdown">
//       <div id="google_translate_element" style={{ display: 'none' }}></div>
//       <div className="dropdown-select">
//         <div className="select-selected" onClick={() => setShowDropdown(!showDropdown)}>
//           {selectedLanguage}
//         </div>
//         {showDropdown && (
//           <div className="header_top_dropdown_over">
//             {languages.map((lang) => (
//               <div key={lang.code} onClick={() => handleLanguageChange(lang.name)}>
//                 <span className="flag">{lang.flag}</span> {lang.name}
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default GoogleTranslate;

// import React, { useEffect, useState } from "react";
// import "./CustomGoogleTranslate.css";

// const GoogleTranslate = () => {
//   const [showDropdown, setShowDropdown] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState("English");

//   const languages = [
//     { code: "en", name: "English", flag: "🇬🇧" },
//     { code: "ar", name: "Arabic", flag: "🇸🇦" },
//     { code: "zh-CN", name: "Chinese (Simplified)", flag: "🇨🇳" },
//     { code: "nl", name: "Dutch", flag: "🇳🇱" },
//     { code: "fr", name: "French", flag: "🇫🇷" },
//     { code: "de", name: "German", flag: "🇩🇪" },
//     { code: "it", name: "Italian", flag: "🇮🇹" },
//     { code: "pt", name: "Portuguese", flag: "🇵🇹" },
//     { code: "ru", name: "Russian", flag: "🇷🇺" },
//     { code: "es", name: "Spanish", flag: "🇪🇸" },
//   ];

//   useEffect(() => {
//     const googleTranslateElementInit = () => {
//       new window.google.translate.TranslateElement(
//         {
//           pageLanguage: "en",
//           includedLanguages: languages.map(lang => lang.code).join(","),
//           layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
//           autoDisplay: false,
//         },
//         "google_translate_element"
//       );
//     };

//     const addScript = () => {
//       const script = document.createElement("script");
//       script.src =
//         "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
//       script.async = true;
//       document.body.appendChild(script);
//     };

//     if (!window.google || !window.google.translate || !window.google.translate.TranslateElement) {
//       window.googleTranslateElementInit = googleTranslateElementInit;
//       addScript();
//     } else {
//       googleTranslateElementInit();
//     }
//   }, []);

//   const changeLanguage = (languageCode) => {
//     if (window.google && window.google.translate) {
//       const changeLanguageInterval = setInterval(() => {
//         const select = document.querySelector("select.goog-te-combo");
//         if (select) {
//           select.value = languageCode;
//           select.dispatchEvent(new Event("change"));
//           clearInterval(changeLanguageInterval);
//         }
//       }, 50);

//       // Clear interval after 5 seconds to prevent infinite loop
//       setTimeout(() => clearInterval(changeLanguageInterval), 5000);
//     }
//   };

//   const handleLanguageChange = (language) => {
//     setSelectedLanguage(language.name);
//     setShowDropdown(false);
//     changeLanguage(language.code);
//   };

//   return (
//     <div className="header_top_dropdown">
//       <div id="google_translate_element" ></div>
//       <div className="dropdown-select">
//         <div className="select-selected" onClick={() => setShowDropdown(!showDropdown)}>
//           {selectedLanguage}
//         </div>
//         {showDropdown && (
//           <div className="header_top_dropdown_over">
//             {languages.map((lang) => (
//               <div key={lang.code} onClick={() => handleLanguageChange(lang)}>
//                 <span className="flag">{lang.flag}</span> {lang.name}
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default GoogleTranslate;
