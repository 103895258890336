import React, { useContext, useState } from 'react'
import { CartContext } from '../store/cartContext';

export default function CartPost() {

  const cartContext = useContext(CartContext)
  const {
    cartContextData: cartPageData,
    updateShippingMethodApi,
  } = cartContext;


  const [selectedOption, setSelectedOption] = useState(cartPageData?.chosenShippingMethods[0] ?? "");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    updateShippingMethodApi(event.target.value);
  };

  return (
    cartPageData?.availableShippingMethods?.length ?
      cartPageData?.availableShippingMethods[0]?.rates?.map((availableShippingMethod, index) => <div key={availableShippingMethod.id}>
        <div className='cart_radio'>  
          <input
            type="radio"
            name={availableShippingMethod.instanceId}
            value={availableShippingMethod.id}
            checked={selectedOption === availableShippingMethod.id}
            onChange={handleOptionChange}
          />
          <label>
            {availableShippingMethod.label} <br /> 
            <span>{availableShippingMethod.cost}</span>
          </label>
        </div>
      </div>)
      : null
  )
}
