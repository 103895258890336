/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

// gatsby-browser.js

import React from "react";
import Layout from "./src/components/helper/layout";
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// export const wrapRootElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

export const wrapPageElement = ({ element, props }) => (<Layout {...props}>
    {element}
    <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
</Layout>)
// gatsby-browser.js
// export const onInitialClientRender = () => {
//     document.body.classList.add('body-manual-class-for-filter');
// };

