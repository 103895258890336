import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { AuthContext } from "../../store/authContext"
import LogoutIcon from "../../../images/logout.png"
import PhoneIcon from "../../../images/phone.png"
import EmailIcon from "../../../images/email.png"
import axios from "axios"
import { toast } from "react-toastify"
import { CurrencyContext } from "../../store/currencyContext"
import { CountryContext } from "../../store/countryContext"


export default function DashboardTab({ userDetails, handleLogout, setCurrentMenu, setAddresses, setLoader, fetchShippingAddressDetails, fetchBillingAddressDetails, fetchContactDetails }) {
    return (
        <div className="dashboard_tab">
            <div className="row">
                <div className="col-lg-12 dashboard_top_content">
                    <p>
                        Hello <b>{userDetails?.username || userDetails?.email}</b>{" "}
                        (not <b>{userDetails?.username || userDetails?.email}</b>?{" "}
                        <span onClick={handleLogout}>
                            <strong>Log out</strong>
                        </span>
                        )
                    </p>
                    <p>
                        From your account dashboard you can view your{" "}
                        <span onClick={() => setCurrentMenu("orders")}>
                            <strong>recent orders</strong>
                        </span>
                        , manage your{" "}
                        <span
                            onClick={() => {
                                setCurrentMenu("address")
                                setAddresses(null)
                                setLoader(true)
                                fetchShippingAddressDetails()
                                fetchBillingAddressDetails()
                            }}
                        >
                            <strong>shipping and billing addresses</strong>
                        </span>{" "}
                        and{" "}
                        <span
                            onClick={() => {
                                setCurrentMenu("account_details")
                                fetchContactDetails()
                            }}
                        >
                            <strong>edit your password and account details</strong>
                        </span>
                        .
                    </p>
                </div>
            </div>
        </div>
    )
}
