import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { AuthContext } from "../../store/authContext"
import LogoutIcon from "../../../images/logout.png"
import PhoneIcon from "../../../images/phone.png"
import EmailIcon from "../../../images/email.png"
import axios from "axios"
import { toast } from "react-toastify"
import { CurrencyContext } from "../../store/currencyContext"
import { CountryContext } from "../../store/countryContext"

export default function DownloadsTab() {
  return (
    <div>
      <p>Replace me with Downloads content</p>
    </div>
  )
}
