import React from 'react'

const ToggleSection = ({ title, isOpen, onToggle, children }) => (
    <div className="wps_wpr_slide_toggle">
      <p className="wps_wpr_view_log_notice wps_wpr_common_slider">
        {title}
        <button onClick={onToggle} className="wps_wpr_open_toggle wps_wpr_plus_icon">
          {isOpen ? '-' : '+'}
        </button>
      </p>
      {isOpen && <div className="wps_wpr_points_view">{children}</div>}
    </div>
  );
  

export default ToggleSection
