import React, { useEffect, useMemo, useState } from "react"
import { formatNumberWithCommas } from "../../helper/utills"

import ViewPointsLogDetails from "../points/viewPointsLogDetails"
const PointsTab = ({ showLoader, pontsDetails }) => {
  const [viewPointsLog, setViewPointsLog] = useState(false)
  const [viewPointsLogEmpty, setViewPointsLogEmpty] = useState(false)

  const handleTogglePointsLog = () => setViewPointsLog(prev => !prev)
  const handleTogglePointsLogEmpty = () => setViewPointsLogEmpty(prev => !prev)

  useEffect(() => {
    if (!pontsDetails) setViewPointsLogEmpty(false)
  }, [pontsDetails])

  const renderEmptyPoints = useMemo(() => {
    if (viewPointsLogEmpty) {
      return <h3>No Points Generated Yet.</h3>
    }
    return (
      <>
        <div className="points_details">
          <div className="row">
            <div className="col-lg-12 points_details_top">
              <h3>My Points:{"0"}</h3>
              <button onClick={handleTogglePointsLogEmpty}>
                View Point Log
              </button>
              <h3>Ways to gain more points:</h3>
              <fieldset>
                <span>For every $1 spent, get 1 point.</span>
              </fieldset>
            </div>
          </div>
        </div>
      </>
    )
  }, [viewPointsLogEmpty])

  return showLoader ? (
    <div className="loader_bg_auth">
      <div class="loader_auth"></div>
    </div>
  ) : viewPointsLog ? (
    <ViewPointsLogDetails pontsDetails={pontsDetails} />
  ) : (
    <div className="account_details_tab">
      {pontsDetails ? (
        <>
          <div className="points_details">
            <div className="row">
              <div className="col-lg-12 points_details_top">
                <h3>
                  My Points:{" "}
                  {formatNumberWithCommas(pontsDetails?.totalRemainPoits)}
                </h3>
                <button onClick={handleTogglePointsLog}>View Point Log</button>
                <h3>Ways to gain more points:</h3>
                <fieldset>
                  <span>For every $1 spent, get 1 point.</span>
                </fieldset>
              </div>
            </div>
          </div>
        </>
      ) : (
        renderEmptyPoints
      )}
    </div>
  )
}

export default PointsTab
