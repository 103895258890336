import React, { createContext, useEffect, useState } from "react"

const currencyContextSessionValue =
  typeof window !== "undefined"
    ? localStorage.getItem("currencyContextSessionValue")
    : ""

const defaultCurrency = {
  "code": "AUD",
  "rate": "1",
  "symbol": "$"
}

export const CurrencyContext = createContext()

export const CurrencyContextProvider = ({ children }) => {
  const [currencyContextData, setCurrencyContextData] = useState({})

  useEffect(() => {
    if(currencyContextSessionValue){
      setCurrencyContextData(currencyContextSessionValue);
    }else{
      setCurrencyContextData(defaultCurrency);
      localStorage.setItem("currencyContextSessionValue", JSON.stringify(defaultCurrency));
    }
  },[])

  const [currencyContextList, setCurrencyContextList] = useState([])

  return (
    <CurrencyContext.Provider
      value={{
        currencyContextData,
        setCurrencyContextData,
        currencyContextList,
        setCurrencyContextList,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}
