// localStorageUtils.js

const CART_KEY = 'cart';

// Get cart from localStorage
export const getCartFromLocalStorage = () => {
    try {
        const storedCart = localStorage.getItem(CART_KEY);
        if (storedCart) {
            return JSON.parse(storedCart);
        } else {
            // Return a default cart structure if nothing is found in localStorage
            return {
                contents: {
                    itemCount: 0,
                    nodes: [],
                    productCount: 0
                },
                appliedCouponsData: [],
                subtotal: "$0.00",
                shippingTotal: "$0.00",
                needsShippingAddress: false,
                chosenShippingMethods: [],
                availableShippingMethods: [],
                total: "$0.00"
            };
        }
    } catch (error) {
        console.error("Error retrieving cart from localStorage:", error);
        // Return a default cart structure in case of an error
        return {
            contents: {
                itemCount: 0,
                nodes: [],
                productCount: 0
            },
            appliedCouponsData: [],
            subtotal: "$0.00",
            shippingTotal: "$0.00",
            needsShippingAddress: false,
            chosenShippingMethods: [],
            availableShippingMethods: [],
            total: "$0.00"
        };
    }
};


// Set cart to localStorage
export const setCartToLocalStorage = (cart) => {
    localStorage.setItem(CART_KEY, JSON.stringify(cart));
};

// Remove cart from localStorage
export const removeCartFromLocalStorage = () => {
    localStorage.removeItem(CART_KEY);
};
