import React from "react"
import { formatPrice, formatPriceForNonAuth } from "./utills"

const Price = ({
  regularPrice,
  salePrice,
  currency,
  isRequiredCurrencySymball = false,
  needToFixed = false,
}) => {
  const updatedCurrency = { ...currency }
  updatedCurrency.rate = "1"

  return (
    <div className="price">
      {/* {currency?.symbol} */}
      {regularPrice && salePrice ? (
        <>
          <del>
            <span className="woocommerce-Price-amount amount">
              <bdi>
                {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}
                {needToFixed ? (regularPrice ?? 0)?.toFixed(2) : regularPrice}
              </bdi>
            </span>
          </del>
          <ins>
            <span className="woocommerce-Price-amount amount">
              <bdi>
                {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}
                {needToFixed ? (salePrice ?? 0)?.toFixed(2) : salePrice}
              </bdi>
            </span>
          </ins>
        </>
      ) : regularPrice ? (
        <ins>
          <span className="woocommerce-Price-amount amount">
            <bdi>
              {isRequiredCurrencySymball ? updatedCurrency?.symbol : ""}
              {needToFixed ? (regularPrice ?? 0)?.toFixed(2) : regularPrice}
            </bdi>
          </span>
        </ins>
      ) : (
        <span
          style={{
            fontSize: "12px",
          }}
        >
          <bdi>Loading Pricing..</bdi>
        </span>
      )}
    </div>
  )
}

export default Price
