import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, navigate } from "gatsby"
import { AuthContext } from "../../store/authContext"
import LogoutIcon from "../../../images/logout.png"
import PhoneIcon from "../../../images/phone.png"
import EmailIcon from "../../../images/email.png"
import axios from "axios"
import { toast } from "react-toastify"
import { CurrencyContext } from "../../store/currencyContext"
import { CountryContext } from "../../store/countryContext"
import OrderDetails from "../orders/OrderDetails"
import CheckIcon from "../../../images/check-mark.png"

export default function OrdersTab({
  showLoader,
  ordersList,
  pageCount,
  setPageCount,
  fetchOrderDetails,
  setLoader,
}) {
  const [orderNumber, setOrderNumber] = useState(null)

  function handleProductDetails(orderNumber) {
    setOrderNumber(orderNumber)
  }

  return showLoader ? (
    <div className="loader_bg_auth">
      <div class="loader_auth"></div>
    </div>
  ) : orderNumber ? (
    <OrderDetails setOrderNumber={setOrderNumber} orderNumber={orderNumber} />
  ) : ordersList && ordersList?.orderItemData.length === 0 ? (
    <div className="no_product_main checkout_top_box order_page_top_no_product">
      <div className="address_tab">
        <div className="save_address">
          <div className="np_box"></div>
          <div className="checkout_point_box">
            <span>No order has been made yet.</span>
          </div>
          <div className="browse_product_button">
            <Link to="/selection/all/products">
              <button>Browse Products</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="order_tab">
      <div className="order_table responsive_table">
        <table cellPadding={0} cellSpacing={0} border={1}>
          <thead>
            <tr>
              <th>Order</th>
              <th>Date</th>
              <th>Status</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ordersList &&
              ordersList?.orderItemData.length > 0 &&
              ordersList?.orderItemData.map(order => (
                <tr>
                  <td data-th="Order">
                    <Link>
                      <button
                        onClick={() => handleProductDetails(order.orderNumber)}
                      >
                        {order?.orderNumber}
                      </button>
                    </Link>
                  </td>
                  <td data-th="Date">{order.date}</td>
                  <td data-th="Date">{order.status}</td>
                  <td data-th="Date">{order.totalCount}</td>
                  <td data-th="Actions">
                    <Link>
                      <button
                        className="order_view_btn"
                        onClick={() => handleProductDetails(order.orderNumber)}
                      >
                        View
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {ordersList?.hasPreviousPage ? (
        <button
          className="prev_next_btn"
          onClick={() => {
            const count = pageCount - 1
            setPageCount(_prevState => _prevState - 1)
            fetchOrderDetails(count)
          }}
        >
          Previous
        </button>
      ) : null}
      {ordersList?.hasNextPage ? (
        <button
          className="prev_next_btn"
          onClick={() => {
            const count = pageCount + 1
            setPageCount(_prevState => _prevState + 1)
            fetchOrderDetails(count)
          }}
        >
          Next
        </button>
      ) : null}
      <br />
      <br />
      <br />
    </div>
  )
}
