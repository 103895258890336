import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { AuthContext } from "../../store/authContext"
import LogoutIcon from "../../../images/logout.png"
import PhoneIcon from "../../../images/phone.png"
import EmailIcon from "../../../images/email.png"
import EyeIcon from "../../../images/eye.png"
import EyeHideIcon from "../../../images/eye-hide.png"
import axios from "axios"
import { toast } from "react-toastify"
import { CurrencyContext } from "../../store/currencyContext"
import { CountryContext } from "../../store/countryContext"

export default function AccountDetailsTab({ showLoader, contactDetails, currencyContextData, currencyContextList, selectCurrency, handleChangeContactDetails, handleSubmitContactDetails }) {
    
  const [isHide, setHide] = useState(false)
  const [isConfirmHide, setConfirmHide] = useState(false)
  const [isNewHide, setNewHide] = useState(false)
    return (
        showLoader ? (
            <div className="loader_bg_auth">
                <div class="loader_auth"></div>
            </div>
        ) :
            <div className="account_details_tab">
                <div className="account_details_form">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <label>First Name <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                name="firstname"
                                value={contactDetails.firstname}
                                onChange={handleChangeContactDetails}
                            />
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <label>Last Name <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                name="lastname"
                                value={contactDetails.lastname}
                                onChange={handleChangeContactDetails}
                            />
                        </div>
                        <div className="col-sm-12">
                            <label>Display Name <span className="asterisk">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                name="displayname"
                                value={contactDetails.displayname}
                                onChange={handleChangeContactDetails}
                            />
                            <p>
                                <i>
                                    This will be how your name will be displayed in the
                                    account section and in reviews
                                </i>
                            </p>
                        </div>
                        <div className="col-sm-12">
                            <label>Email Address <span className="asterisk">*</span></label>
                            <input
                                type="email"
                                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                                className="form-control"
                                name="email"
                                value={contactDetails.email}
                                onChange={handleChangeContactDetails}
                                autocomplete="off"
                            />
                        </div>
                        <div className="col-sm-12">
                            <div className="form_caption">
                                <h5>Password Change</h5>
                            </div>
                        </div>
                        <div className="col-sm-12 relative">
                            <label>
                                Current password (leave blank to leave unchanged)
                            </label>
                            <input
                                type={!isHide ? "password" : "text"}
                                className="form-control"
                                name="currentpasswd"
                                value={contactDetails.currentpasswd}
                                onChange={handleChangeContactDetails}
                                autocomplete="new-password"
                            />
                            <div className="password_eye">
                            {!isHide ? (
                                <img src={EyeHideIcon} onClick={() => setHide(true)}  />
                            ) : (
                                <img src={EyeIcon} onClick={() => setHide(false)}  />
                            )}
                            </div>
                        </div>
                        <div className="col-sm-12 relative">
                            <label>
                                New password (leave blank to leave unchanged)
                            </label>
                            <input
                                type={!isNewHide ? "password" : "text"}
                                className="form-control"
                                name="newpasswd"
                                value={contactDetails.newpasswd}
                                onChange={handleChangeContactDetails}
                                autocomplete="new-password"
                            />
                            <div className="password_eye">
                            {!isNewHide ? (
                                <img src={EyeHideIcon} onClick={() => setNewHide(true)}  />
                            ) : (
                                <img src={EyeIcon} onClick={() => setNewHide(false)}  />
                            )}
                            </div>
                        </div>
                        <div className="col-sm-12 relative">
                            <label>Confirm New password</label>
                            <input
                                type={!isConfirmHide ? "password" : "text"}
                                className="form-control"
                                name="confirmpasswd"
                                value={contactDetails.confirmpasswd}
                                onChange={handleChangeContactDetails}
                                autocomplete="new-password"
                            />
                            <div className="password_eye">
                            {!isConfirmHide ? (
                                <img src={EyeHideIcon} onClick={() => setConfirmHide(true)}  />
                            ) : (
                                <img src={EyeIcon} onClick={() => setConfirmHide(false)}  />
                            )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="row">
                                <div className="col-sm-12">
                                    <label>Default Currency</label>
                                    <select
                                        className="form-control"
                                        name="defaultcurrency"
                                        value={
                                            currencyContextData?.code ||
                                            contactDetails.defaultcurrency
                                        }
                                        onChange={_e => {
                                            selectCurrency(_e)
                                            handleChangeContactDetails(_e)
                                        }}
                                    >
                                        {currencyContextList &&
                                            currencyContextList.map((item, index) => (
                                                <option value={item?.code} key={index}>
                                                    {item?.symbol} {item?.code}
                                                </option>
                                            ))}
                                    </select>
                                    <p>
                                        <i>
                                            Select your preferred currency for shopping and
                                            payments.
                                        </i>
                                    </p>
                                </div>
                                <div className="col-sm-12">
                                    <div class="form-check">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            name="newsletter"
                                            id="news_check_1"
                                            checked={
                                                contactDetails?.newsletter === "1"
                                                    ? true
                                                    : false
                                            }
                                            value={"1"}
                                            onChange={_e => {
                                                handleChangeContactDetails(_e)
                                            }}
                                        />
                                        <label class="form-check-label" for="news_check_1">
                                            Subscribe to our newsletter
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div class="form-check">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            name="newsletter"
                                            id="news_check_2"
                                            checked={
                                                contactDetails?.newsletter === "unsubscribed"
                                                    ? true
                                                    : false
                                            }
                                            value={"unsubscribed"}
                                            onChange={_e => {
                                                handleChangeContactDetails(_e)
                                            }}
                                        />
                                        <label class="form-check-label" for="news_check_2">
                                            Unsubscribe to our newsletter
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div class="form-check">
                                        <input
                                            type="radio"
                                            class="form-check-input"
                                            name="newsletter"
                                            id="news_check_3"
                                            checked={
                                                contactDetails?.newsletter === "0"
                                                    ? true
                                                    : false
                                            }
                                            value={"0"}
                                            onChange={_e => {
                                                handleChangeContactDetails(_e)
                                            }}
                                        />
                                        <label class="form-check-label" for="news_check_3">
                                            Receive Order Updates
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <button onClick={handleSubmitContactDetails}>
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    )
}
